import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ProductModalPage from "../components/ProductModalPage"
import Carousel from '../components/Carousel'
import Title from '../components/Title'
import { connect } from 'react-redux'

const SubPageTemplate = ({ dispatch, useImg, useAlt, useDescription, useCaption, pageContext }) => {

  const { t } = useTranslation()
  const bgColor = '#c4cbd1'

  var carpentry = false
  var kitchen = false

  if (pageContext.type === 'carpintaria') {
    carpentry = true
  }
  if (pageContext.type === 'cozinhas') {
    kitchen = true
  }


  return (
    <div style={{ backgroundColor: bgColor }}>
      <Layout
        header={t("header", { returnObjects: true })}
        footer={t("footer", { returnObjects: true })}
        bgColor={bgColor}
        carousel={t("products", { returnObjects: true })}
        orcamento={t("orcamento", { returnObjects: true })}
      >
        <Seo title={pageContext.type} />

        <ProductModalPage content={pageContext} bgColor={bgColor} img={useImg}
          caption={useAlt}
          description={useDescription}
          alt={useCaption}
          carpentry={carpentry}
          kitchen={kitchen} />

        <Title title={t("carpentry", { returnObjects: true }).subpage.title} />


        {pageContext.info.images.length > 1 &&
          <Carousel
            carousel={pageContext.info.images}
            carpentry={carpentry} kitchen={kitchen}
            dispatch={dispatch}
            products={t("products", { returnObjects: true })}
          />
        }
      </Layout>
    </div>
  )

}


export default connect(state => ({
  useImg: state.app.img,
  useAlt: state.app.alt,
  useDescription: state.app.description,
  useCaption: state.app.caption,
  product: state.app.product
}), null)(SubPageTemplate)


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "carpentry", "products", "orcamento"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`