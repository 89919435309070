import React from 'react'
import styled from 'styled-components'
import { media } from './MediaQueries'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Grid, Cell } from "styled-css-grid"


const Title = ({ title }) => {
  const breakpoints = useBreakpoint()

  return (
    <TitleStyled>
      <p>{title}</p>
    </TitleStyled>
  )
}

export default Title


const TitleStyled = styled.div`
   text-transform: uppercase
   width:100%;
   margin: auto;
   text-align: center;
   p{
    text-transform: uppercase;
    font-family:'Exo', sans-serif;
    letter-spacing: 5px;
    font-size: 3.5rem;
    font-weight: 100;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-top: 5pc;
    padding-bottom: 5pc;
   }
   @media (max-width: 990px){
    padding-left: 5%;
    padding-right: 5%;
    p{
      font-size: 1.5rem;
    }
   }
`